import request from './request'
// 查看同学
export function chakantongxue(params) {
  return request({
    url: '/lesson/lesson/getClassStudent',
    method: 'GET',
    params
  })
}
// 班级首页
export function banjishouye(params) {
  return request({
    url: '/lesson/lesson/getScheduleByLessonId',
    method: 'GET',
    params
  })
}
// 课表试卷 获取弹窗信息
export function getEvaluationInfo(params) {
  return request({
    url: 'lesson/lesson/getEvaluationInfo',
    method: 'get',
    params
  })
}
// 班级课表
export function banjikebiao(params) {
  return request({
    url: '/lesson/lesson/getSchedule',
    method: 'GET',
    params
  })
}
// 直播预告
export function getNotice(params) {
  return request({
    url: '/educlass/educlass/getClassLive',
    method: 'GET',
    params
  })
}
// 直播预告
export function get_achievement(params) {
  return request({
    url: '/lesson/lesson/classStudentScore',
    method: 'GET',
    params
  })
}
// 获取课表信息
export function getLessonInfo(params) {
  return request({
    url: '/lesson/lesson/getLesson',
    method: 'GET',
    params
  })
}
// 直播日历
export function getClassLiveByLessonId(params) {
  return request({
    url: 'lesson/lesson/getClassLiveByLessonId',
    method: 'GET',
    params
  })
}
// 获取班级信息
export function getClassTeam(params) {
  return request({
    url: '/lesson/lesson/getClassTeam',
    method: 'get',
    params
  })
}
export function getClassStudentScore(params) {
  return request({
    url: '/lesson/lesson/getClassStudentScore',
    method: 'get',
    params
  })
}