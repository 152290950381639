<template>
  <div>
    <header>
      <ul>
        <li>
          <img @click="tui" src="../../icon/返回.png" class="img1" alt="" />
        </li>
        <li class="li2">班级课表</li>
        <li>
          <img src="../../icon/更多.png" alt="" />
        </li>
      </ul>
    </header>
    <van-collapse v-model="activeNames">
      <van-collapse-item name="1">
        <template #title>
          <div>第一章</div>
        </template>
        <div class="shipin">
          <img src="../../icon/视频播放.png" />
          <div class="lia">
            <p class="moxing">模型导读以及前期工作准备</p>
            <p>
              <span>{{ this.fen }}</span
              >分钟 <span>131.70</span>M <span class="baifen">已学 0%</span>
            </p>
          </div>
          <div class="xiazai">
            <van-icon name="down" size="30px" class="ico" />
          </div>
        </div>

       


       
      </van-collapse-item>
    </van-collapse>

    <van-tabbar v-model="active" active-color="#ee0a24" inactive-color="#000">
      <van-tabbar-item icon="wap-home-o" @click="shouye">首页</van-tabbar-item>
      <van-tabbar-item class="daohang2">
        <img
          src="../../icon/课表.png"
          alt=""
          class="kebiao"
        />课表</van-tabbar-item
      >
      <van-tabbar-item icon="comment-o" @click="wenda">问答</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { banjikebiao } from "@/utils/index1.js";
export default {
  data() {
    return {
      activeNames: ["1"],
      currentRate: 10,
      active: 1,
      lesson_id:'',
      class_id:'',
      student_id:'',
      class_name:'',
      fen: 16,
    };
  },
  mounted() {
    this.lesson_id = this.$route.query.lesson_id
    this.class_id = this.$route.query.class_id
    this.student_id = this.$route.query.student_id
    this.class_name = this.$route.query.class_name
    this.kebiao_list();
  },

  methods: {

    kebiao_list() {
      var params = {
        // class_id : this.$route.query.class_id,
        // lesson_id:1,
        // class_name:this.$route.query.class_name,
        // student_id:this.$route.query.student_id
        lesson_id: this.lesson_id,
        class_id: this.class_id,
        student_id: this.student_id,
        class_name: this.class_name
      };
      banjikebiao(params).then((res) => {
        console.log(res);
      });
    },
    tui() {
      this.$router.go(-1);
    },
    shouye() {
      this.$router.push({
        path: '/banji',
        query: {
          class_id: this.class_id,
          lesson_id: this.lesson_id,
          student_id: this.student_id,
          class_name: this.class_name
        }
      })
    },
    wenda() {
      this.$router.push({
        path: '/wenda',
        query: {
          class_id: this.class_id,
          lesson_id: this.lesson_id,
          student_id: this.student_id,
          class_name: this.class_name
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.ico {
  margin-left: 10px;
  margin-top: 10px;
}
.xiazai {
  width: 80px;
  height: 80px;
  border: 1px solid rgb(150, 151, 153);
  border-radius: 50px;
  margin-top: 20px;
  margin-left: 30px;
}
.baifen {
  color: rgb(190, 39, 39);
}
.moxing {
  font-size: 34px;
  font-weight: 500;
  color: #000;
  margin-top: 16px;
}
.kebiao {
  position: fixed;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // top: -30px;
  width: 85px;
  height: 85px;
  // border: 1px solid #000;
  // padding: 4spx;
  // width: 80px;
  // height: 80px;
  background-color: red;
  border-radius: 50%;
  bottom: 0.66667rem;
  left: 334px;
  z-index: 666;
  box-shadow: 0.01rem 0rem 0.1rem 5px white;
}
.daohang2 {
  display: flex;
  // flex-direction: column;
  justify-content: center;
  // align-items: center;
  // position: relative;
  padding-top: 34px;
  // padding-right: 20px;
}
.lia {
  width: 455px;
  height: 100%;
  // background: red;
}
.shipin {
  width: 98%;
  height: 124px;
  // background: skyblue;
  margin: 0 auto;
  display: flex;
  margin-top: 6px;
  border-bottom: 1px solid rgb(201, 191, 191);
  img {
    width: 90px;
    height: 130%;
    margin-left: -2px;
  }
}
.li2 {
  width: 600px;
}
header {
  width: 100%;
  height: 174px;
  background: rgb(255, 255, 255);
  ul {
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(0, 0, 0);
    font-size: 40px;
    text-align: center;
    li {
      width: 200px;
      height: 60px;
      margin-top: 140px;
    }
  }
}
</style>
<style>
.van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  line-height: 70px;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: rgb(238, 238, 238) !important;
}
.van-cell {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 0.933333rem;
  line-height: 0.933333rem;
  padding: 0.133333rem 0.213333rem;
  overflow: hidden;
  color: #323233;
  font-size: 0.186667rem;
  line-height: 60px;
  background-color: rgb(238, 238, 238) !important;
}
</style>